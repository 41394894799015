 html,
 body {
   touch-action: pan-x, pan-y;
 }

body {
  position: relative;
}

body::after {
  content: '';
  display: block;
  padding-bottom: 250px; /* Set same as footer's height */

  @media only screen and (max-width: 1024px) {
    padding-bottom: 0
  }
}

::-webkit-scrollbar-thumb {
  background: #10125A;
} 

::-webkit-scrollbar {
  width: 8px
}

::-webkit-scrollbar-track {
  width: 1px
}

footer {
    position: absolute;
    height: 250px;
    bottom: 0;
    width: 100%;

    @media only screen and (max-width: 1024px) {
      position: initial;
      height: auto;
    }
}