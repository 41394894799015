.container-login-topo {
    position: absolute;
    width: 100%;
    justify-content: end;
    margin: 0 auto;
    padding-right: 100px;
    // max-width: 1420px;

    .container-login {
        .login {
            border-radius: 0px;
            opacity: 0.8;
            border-bottom-left-radius: 25px;
            border-bottom-right-radius: 25px;

            .form {
                .label {
                    font-size: 14px;
                }
            }
        }
    }
}

.container-login {
    .login {
        align-items: flex-start;
        margin: 0 auto;
        // width: 850px;
        background-color: #10125A;
        border-radius: 35px;
        padding: 20px;
        max-height: 155px;
        // opacity: 0.8;

        @media only screen and (max-width: 1024px) {
            width: auto;
            margin: 10px;
        }

        @media only screen and (max-width: 600px) {
            width: auto;
            border-radius: 0;
            padding: 20px;
            max-height: 400px;
            flex-wrap: wrap;
            margin: 0;

            .form-login {
                flex-wrap: wrap;
            }
        }

        .form {
            flex-direction: column;

            @media only screen and (max-width: 600px) {
                width: 100%;

                &.cadastro {
                    input[type="text"] {
                        margin-right: 15px;
                    }
                }
            }

            .label {
                font-size: 18px;
                font-weight: bold;
                margin-bottom: 10px;
                color: #FFFF
            }

            input[type="text"],
            input[type="password"] {
                font-size: 12px;
                width: 100%;
                min-height: 30px;
                background-color: rgb(237, 237, 237);
                border: 1px solid transparent;
                border-radius: 5px;
                padding-left: 10px;
                margin-right: 15px;

                @media only screen and (max-width: 600px) {
                    margin-bottom: 15px;
                    min-height: 40px;
                    margin-right: 0;
                }
            }

            button {
                padding: 0 25px;
                margin-left: 0;
            }
        }
    }
}