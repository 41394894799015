@import url("https://use.typekit.net/psi4xcn.css");

.footer-container {
  
  .content {
    display: flex;
    justify-content: center;
    align-items: center;
    position: relative;
    max-width: 1200px;
    width: 90%;
    margin: 0px auto;
    max-height: 359px;
    padding: 35px 0px;

    .logo {
      max-width: 10%;
      margin-bottom: 60px;
      margin-top: -10px;
      img {
        width: 100%;
      }
    }

    .informations {
      width: 90%;
      max-width: 90%;
      padding-left: 35px;
      color: #fff;

      a {
        color: #fff;
        
        font-size: 0.875rem;
        margin-bottom: 5px;
        font-weight: 500;
      }

      small {
        font-size: 0.7rem;
        
      }

      hr {
        margin: 10px auto;
      }

      > div {
        max-width: 100%;
        display: flex;
        justify-content: space-between;
        align-items: center;
        .content1 {
          width: 80%;
          display: flex;
          justify-content: flex-start;
          align-items: flex-start;
          &.big-links {
            a {
              font-size: 1.25rem;
            }
          }
        }
        .child1 {
          width: 190px;
          display: flex;
          flex-direction: column;
        }
        .child2 {
          width: 300px;
          display: flex;
          flex-direction: column;
        }
        .child3 {
          width: 170px;
          display: flex;
          flex-direction: column;
        }

        .content2 {
          display: flex;
          img {
            margin-left: 15px;
          }
        }

        &:nth-child(3) {
          margin-bottom: 25px;
        }
      }
    }
  }
}
