@import url("https://use.typekit.net/psi4xcn.css");

.container-seguros {
  display: flex;
  width: 100%;
  flex-direction: column;

  @media only screen and (max-width: 992px) {
   
  }

  .line-red {
    width: 100%;
    height: 50px;
    background-color: #cc0100;
    @media only screen and (max-width: 992px) {
      height: 30px;
    }
  }

  .header-title {
    background-color: #EC1C24;
    padding-top: 25px;
    padding-bottom: 25px;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    @media only screen and (max-width: 992px) {
      padding-top: 15px;
      padding-bottom: 15px;
    }

    .title {
      font-size: 35px;
      font-weight: bold;
      color: #fff;
      text-align: center;
      @media only screen and (max-width: 992px) {
        font-size: 16px;
      }
    }
    .sub-title {
      font-size: 25px;
      font-weight: 600;
      color: #fff;
      margin-top: 10px;
      @media only screen and (max-width: 992px) {
        font-size: 12px;
      }
    }
  }

  .banner {
    img {
      width: 100%;
      cursor: pointer;
    }
  }

  .center-text {
    background-color: #10125A;
    padding-top: 35px;
    padding-bottom: 35px;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    @media only screen and (max-width: 992px) {
      padding-top: 15px;
      padding-bottom: 15px;
    }
    .title {
      font-size: 30px;
      font-weight: bold;
      color: #fff;
      text-align: center;
      @media only screen and (max-width: 992px) {
        font-size: 14px;
      }
    }
    .sub-title {
      font-size: 30px;
      font-weight: 600;
      color: #fff;
      margin-top: 10px;
      text-align: center;
      @media only screen and (max-width: 992px) {
        font-size: 14px;
      }
    }
  }
  
  .container-itens-seguros {
    display: flex;
    flex-direction: column;
    margin-top: 100px;
    .item {
      display: flex;
      flex-direction: column;
      width: 350px;
      height: 580px;
      box-shadow: 0px 0px 26px 0px rgba(153,153,153,0.79);
      background-color: #fff;
      border-radius: 50px;
      @media only screen and (max-width: 992px) {
        width: 300px;
        height: 450px;
      }
      .icon-header {
        display: flex;
        justify-content: center;
        align-items: center;

        img {
          height: 150px;
          width: 150px;
          margin-top: -50px;
          @media only screen and (max-width: 992px) {
            height: 100px;
            width: 100px;
          }
        }
      }

      .content {
        display: flex;
        justify-content: center;
        align-items: center;
        flex-direction: column;
        margin-top: 30px;

        .title {
          font-size: 35px;
          font-weight: 900;
          color: #10125A;
          text-align: center;
          @media only screen and (max-width: 992px) {
            font-size: 30px;
          }
        }

        .line {
          width: 100%;
          margin-top: 30px;
          display: flex;
          align-items: center;
          justify-content: center;
          text-align: center;

          .line-item {
            width: 35%;
            height: 2px;
            background-color: #10125A;
            border-radius: 6px;
          }
        }

        .subtitle {
          font-size: 20px;
          font-weight: 600;
          color: #10125A;
          text-align: center;
          margin-top: 30px;
          @media only screen and (max-width: 992px) {
            font-size: 20px;
          }
        }

        .price {
          display: flex;
          flex-direction: row;
          align-items: center;
          margin-top: 40px;
  
          .price-1 {
            display: flex;
            flex-direction: column;
            
  
            .price-1-title {
              font-size: 20px;
              font-weight: 600;
              color: #10125A;
              text-align: right;
              margin-top: -0px;
              margin-right: 5px;
              @media only screen and (max-width: 992px) {
                font-size: 14px;
                margin-top: 1px;
              }
            }
  
            .price-1-subtitle {
              font-size: 30px;
              font-weight: bold;
              color: #10125A;
              text-align: right;
              margin-right: 10px;
              margin-top: 6px;
              @media only screen and (max-width: 992px) {
                font-size: 30px;
                margin-top: 2px;
              }
            }
          }

          .price-2 {
            display: flex;
            flex-direction: column;
  
            .price-2-title {
              font-size: 80px;
              font-weight: bold;
              color: #10125A;
              letter-spacing: -4px;
              @media only screen and (max-width: 992px) {
                font-size: 60px;
              }
            }
          }

          .price-3 {
            display: flex;
            flex-direction: column;
            margin-left: 5px;
  
            .price-3-title {
              font-size: 55px;
              color: #10125A;
              font-weight: 800;
              line-height: 35px;
              @media only screen and (max-width: 992px) {
                font-size: 35px;
              }
            }
  
            .price-3-subtitle {
              font-size: 20px;
              font-weight: bold;
              color: #10125A;
              @media only screen and (max-width: 992px) {
                font-size: 16px;
              }
            }
          }

        }

      }
      .container-butotn-buy {
        width: 100%;
        display: flex;
        justify-content: center;
        align-items: center;
        margin-top: 75px;

        .button-buy {
          height: 80px;
          background-color: #EC1C24;
          border-radius: 10px;
          color: #fff;
          font-weight: bold;
          text-align: center;
          display: flex;
          justify-content: center;
          align-items: center;
          font-size: 25px;
          width: 250px;
          border: none;
          outline: none;
          cursor: pointer;
          z-index: 9;
          @media only screen and (max-width: 992px) {
            font-size: 20px;
            height: 65px;
            width: 200px;
            margin-top: -30px;
          }
        }
      }
    }
  }

}

.container-cart-items {
  display: flex;
  flex-direction: column;
  flex: 1;
  border: none;
  @media only screen and (max-width: 992px) {
    border: none;
  }
}