.faq-item{
    width: 100%; 
    align-items: center;
    display: flex;
    flex-direction: column;
    padding-top: 10px;
    padding-bottom: 10px;
  }
  
  .faq-item-subitem {
      min-width: 100%;
      padding: 10px;
      display: none;
      background: white;
      border: 1px solid #e0e0e0;
      padding-left: 10px;
      border-top: 0px;
      &.is-open {
        min-width: 100%;
        padding: 10px;
        height: auto;
        display: flex;
        border-bottom-left-radius: 10px;
        border-bottom-right-radius: 10px;
      }
  }
  
  .faq-select {
    flex: 1;
    min-width: 100%;
    padding: 15px;
    display: flex;
    flex-direction: row;
    justify-content: center; 
    align-items: center; 
    max-width: 100%;
    border-top-left-radius: 10px;
    border-top-right-radius: 10px;
    background-color: #f5f5f5;
    &.is-open {
      .select-icon {
        transition: 1s;
        transform: rotate(180deg);
        -webkit-transform: rotate(180deg);
      }
    }
  }
  
  .select-icon {
    transition: 1s;
    color: black;
  } 

  .faq-title-category{
    color: #10125A;
    font-weight: bold;
    font-family: "Montserrat";
  }