@import url("https://use.typekit.net/psi4xcn.css");

* {
  -webkit-tap-highlight-color: transparent;
}

.header-container {
  height: 10px;
  text-align: center;
  
  .red-stripe {
    position: fixed;
    top: 10;
    width: 100%;
    // height: 20px;
    padding: 6px;
    background-color: #b40f15;
    z-index: 10;
  }
}

.header-container-logged {
  height: 50px;
  .red-stripe {
    // max-height: 50px;
    position: fixed;
    top: 0px;
    width: 100%;
    background-color: #b40f15;
    z-index: 10;
  }
}

.container-menu {
  // margin-top: calc(var(--chakra-space-3) * -1);
  background-color:#EC1C24 ;
  width: 100%;
  height: 130px;
  //margin-bottom: 20px;
  z-index: 50;
}

.menu {
  align-items: flex-end;
  justify-content: space-between;
  max-width: 1420px;
  margin: 0 auto;

  :hover{
    cursor: pointer;
    background-color: #10125A;
  }

  .menu-item {
    flex-direction: column;
    align-items: center;
    width: 140px;
    height: 100%;
    justify-content: center;
    padding: 2px;
    color: #ffff;

    .menu-image {
      width: 44px;
      height: 44px;
    }
  
    .menu-text {
      margin-top: 8.3px;
      font-size: 14px;
      font-weight: 600;
      margin-bottom: 0;
      color: #ffff;
      text-align: center;
    }
  }
}

.ambiente-teste {
  background-color: yellow;
  color: #EC1C24;
  text-align: center;    
  font-weight: 500;
  z-index: 10;
  padding: 5px;

  @media only screen and (max-width: 680px) {
    margin-bottom: 10px;
  }

  span {
    padding: 5px;
  }
}

.container-cart-button {
  height: 50px;
  border-radius: 50px;
  display: flex;
  align-items: center;
  justify-content: center; 
  z-index: 999;
  position: fixed;
  top: 40px;
  right: 8.5%;

  @media (min-width:681px) and (max-width:993px) {
    right: 5%;
  }

  @media (min-width:994px) and (max-width:1200px) {
    right: 10%;
  }

  @media (min-width:1201px) and (max-width:1341px) {
    right: 12%;
  }

  @media (min-width:1341px) and (max-width:1500px) {
    right: 16%;
  }

  @media (min-width:1501px) and (max-width:1600) {
    right: -100%;
  }


  @media only screen and (max-width: 680px) {
    right: 20px;
  }


}


.abaCarrinhoHeader {
  width: 370px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  position: fixed !important;
  z-index: 9999 !important;

  @media only screen and (max-width: 1024px) {
    width: 90%;
  }

  .headerAbaCarrinhoHeader {
    width: 90%;
    height: 5%;
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin: 20px auto 20px;

    strong {
      margin-bottom: 5px;
      font-family: "Noto Serif TC";
      font-weight: 900;
      font-size: 20px;
      color: #e84926;

      @media only screen and (max-width: 1024px) {
        font-size: 18px;
      }
    }

    span {
      font-family: 'Muli';
      font-size: 12px;
      color: #bfbfc0;
      text-transform: uppercase;
      margin-right: 100px;

      @media only screen and (max-width: 1024px) {
        margin-right: 90px;
        font-size: 12px;
      }
    }

    .btn {
      width: 15px;
      height: 15px;
      display: block;
      border-radius: 0;
      padding: 0 0 0;
      margin: 0 0 0;
      background: url(../../assets/icons/plus.png) no-repeat center center;
      background-size: contain;
    }
  }
}

.mainAbaCarrinhoHeader {
  width: 90%;
  height: 95%;
  margin: 0 auto 0;
  border-top: solid 1px #e1e1e1;
  padding: 25px 20px 0 0px;
  overflow-y: auto;

  .contentCarrinhoVazio{
    width: 100%;
    display: flex;
    flex-direction: column;
    margin: 250px auto 0;
    padding: 0 0 0;
    font-family: "Muli";
    text-align: center;
    color: #636262;
    font-size: 16px;
    font-weight: 700;
    strong{
      font-weight: 700;
      color: #e84926;
      text-transform: uppercase;
    }
    a{
      width: 100%;
      height: 50px;
      display: flex;
      margin: 60px auto 0;
      justify-content: center;
      align-items: center;
      border-radius: 5px;
      font-family: "Noto Serif TC";
      font-weight: 900;
      background: #e84926;
      color: #fff;
      font-size: 21px;
    }
    @media only screen and (max-width: 1024px){
      margin-top: 50px;
      font-size: 13px;
      line-height: 16px;
      strong{
        margin-bottom: 5px;
        font-size: 13px;
      }
      a{
        margin-top: 30px;
        height: 40px;
        font-size: 18px;
      }
    }
  }

  @media only screen and (max-width: 1024px) {
    padding: 25px 15px 0 0px;
  }

  .abaProduto {
    width: 100%;
    position: relative;
    margin-bottom: 50px;

    @media only screen and (max-width: 1024px) {
      margin-bottom: 25px;
    }

    .btn {
      &.btoExcluirProduto {
        width: 15px;
        height: 15px;
        display: block;
        position: absolute;
        top: 0;
        right: 0;
        border-radius: 0;
        padding: 0 0 0;
        margin: 0 0 0;
        background: url(../../assets/icons/plus.png) no-repeat center center;
        background-size: contain;
      }
    }

    .prd {
      display: flex;
      justify-content: flex-start;
      align-items: flex-start;

      img {
        width: 62px;
        display: block;
      }

      p {
        width: 70%;
        margin: 0 0 0 10px;
        padding: 0 0 0 0;
        font-family: 'Muli';
        font-weight: 900;
        font-size: 12px;
        line-height: 14px;
        color: #636262;
        text-transform: uppercase;

        @media only screen and (max-width: 1024px) {
          width: 60%;
          font-size: 12px;
          line-height: 14px;
        }

        strong {
          display: block;
          margin-top: 10px;
          font-size: 12px;
          color: #e84926;
          font-weight: 900;

        }
      }
    }
  }
}

.abaTotalPontos {
  width: 100%;
  height: 60px;
  display: flex;
  padding: 0 25px 0 25px;
  box-sizing: border-box;
  justify-content: space-between;
  align-items: center;
  background: #f6b6cd;

  span {
    font-family: 'Muli';
    font-weight: 700;
    font-size: 12px;
    color: #e84926;
    text-transform: uppercase;
  }
}

.rodapeAbaCarrinho {
  width: 90%;
  display: flex;
  flex-direction: column;
  margin: 30px auto 40px;

  @media only screen and (max-width: 1024px) {
    margin: 20px auto 20px;
  }

  .btn {
    margin-top: 0;
    width: 100% !important;
    display: flex;
    justify-content: center;
    align-items: center;
    height: 40px;
    color: #fff;
    background-color: #e84926;
    box-shadow: none;
    border-radius: 5px;
    font-size: 15px;
    font-family: "Noto Serif TC";
    font-weight: 900;
    text-transform: none;
    &:hover{
      color: #fff;
    }
  }

  a {
    width: 140px;
    display: block;
    margin: 20px auto 0;
    padding-bottom: 5px;
    font-family: 'Muli';
    font-weight: 700;
    font-size: 11px;
    color: #636262;
    text-align: center;
    border-bottom: solid 1px #e84926;

    &:hover {
      color: #636262;
    }
  }
}

.overlayAbaCarrinhoHeader {
  z-index: 999 !important;
}

.container-first-header {
  display: flex;
  align-items: center;
  justify-content: center;

  .container-item {
    flex-wrap: wrap;
    align-items: center;
    justify-content: center;
    display: flex;
    flex-direction: row;
    height: 50px;
    width: 1300px;
  }

  .item-menu {
    height: 100%;
    display: flex;
    align-items: center;
    padding-left: 12px;
    padding-right: 12px;
  }
  
  @media only screen and (max-width: 1103px) {
    .none-mobile {
      display: none;
    }
    .item-menu {
      padding-left: 4px;
      padding-right: 4px;
    }
  }
}

.container-number-activated {
  display: flex;
  align-items: center;
  justify-content: center;
  position: absolute;
  right: 10px;
  top: 20px;
  height: 30px;
  width: 30px;
  border-radius: 999px;
  background: #fff;
  &:hover {
    background-color: #fff;
  }

  .text-number{
    font-size: 14px;
    color: #EC1C24; 
    font-weight: bold;
    &:hover {
      background-color: #fff;
    }
  }
}

.container-saldo-pontos {
  min-height: auto;
  max-height: 240px;
  position: absolute;
  background-color: #fff;
  top: 50px;
  right: -235px;
  width: 500px;
  border-radius: 10px;
  box-shadow: 0px 3px 10px 3px rgba(0,0,0,0.1);
  overflow-y: scroll;
  scrollbar-width: thin;
  padding: 30px;
  @media only screen and (max-width: 1024px) {
    right: -100%;
    width: 300px;
    padding: 10px;
  }

  @media only screen and (max-width: 400px) {
    right: 0;
  }
}

.container-saldo-pontos::-webkit-scrollbar {
  width: 6px;
}

.container-saldo-pontos::-webkit-scrollbar-thumb {
  background-color: rgba(0, 0, 0, 0.2); 
  border-radius: 6px; 
}

.container-saldo-pontos::-webkit-scrollbar-track {
  background-color: rgba(0, 0, 0, 0.1); 
}


.list-points {
  display: flex;
  flex-direction: row;
  padding-bottom: 10px;
  .title {
    font-size: 20px;
    font-weight: 500;
    color: #10125A;
    //margin-top: 6px;
  }
  .subtitle {
    font-size: 20px;
    font-weight: 700;
    color: #10125A;
  }
}

.container-title {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  padding-bottom: 10px;
  span {
    font-size: 20px;
    font-weight: bold;
    color: #10125A;
    text-decoration: underline;
  }
}