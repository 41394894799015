@import url("https://use.typekit.net/psi4xcn.css");

.container-cart {
  display: flex;
  flex-direction: row;
  width: 100%;

  @media only screen and (max-width: 992px) {
    padding-left: 5px;
    padding-right: 5px;
  }

  @media only screen and (max-width: 992px) {
    flex-direction: column;
  }

}

.container-cart-items {
  display: flex;
  flex-direction: column;
  flex: 1;
  border: none;
  @media only screen and (max-width: 992px) {
    border: none;
  }
  
  border-radius: 12px;
  
  .title-cart {
    padding-left: 30px;
    padding-right: 30px;
    padding-top: 20px;
    width: 100%;
    border-bottom: 2px solid #e0e0e0;
    padding-bottom: 20px;

    .subtitle {
      font-size: 16px;
      color: gray;
      font-weight: 600;
    }
    @media only screen and (max-width: 992px) {
      padding-left: 15px;
      padding-right: 15px;
    }

    span {
      font-size: 30px;
      color: #10125A;
      font-weight: bold;
    }
  }

  .list-products{
    flex-direction: column;
    display: flex;
    padding-left: 30px;
    padding-right: 30px;
    //margin-top: 30px;

    @media only screen and (max-width: 992px) {
      padding-left: 15px;
      padding-right: 15px;
    }

    .title-list-products{
      display: flex;
      flex-direction: row;
      .left-title{
        display: flex ;
        flex: 1;
        align-items: flex-start;
        span {
          font-size: 16px;
          color: gray;
          font-weight: bold;
          @media only screen and (max-width: 992px) {
            font-size: 14px;
          }
        }
      }
      .right-title{
        display: flex;
        flex: 1;
        justify-content: flex-end;
        span {
          font-size: 14px;
          color: gray;
          font-weight: bold;
          @media only screen and (max-width: 992px) {
            font-size: 14px;
          }
        }
      }
    }
  }
}

.container-summary-items {
  display: flex;
  flex-direction: column;
  padding-left: 20px;
  padding-right: 20px;
  width: 400px;

  @media only screen and (max-width: 992px) {
    width: 100%;
    padding-left: 0px;
    padding-right: 0px;
    margin-top: 20px;
  }

  .container-avaible-points{
    display: flex;
    flex-direction: column;
    height: 90px;
    border-radius: 12px;

    .title-points {
      flex: 0.5;
      display: flex;
      align-items: center;
      justify-content: center;
      background-color: #EC1C24;
      border-top-left-radius: 12px;
      border-top-right-radius: 12px;

      span {
        font-size: 16px;
        font-weight: bold;
        color: #fff;
      }
    }

    .points-value {
      flex: 1;
      display: flex;
      align-items: center;
      justify-content: center;
      overflow: scroll;
      border: 2px solid #e0e0e0;
      border-bottom-left-radius: 12px;
      border-bottom-right-radius: 12px;
      border-top: none;
      span {
        font-size: 16px;
        font-weight: bold;
      }
    }

  }

  .container-summary {

    border: 2px solid #e0e0e0;
    border-radius: 10px;

    @media only screen and (max-width: 992px) {
      border-radius: 0px;
    }
    .title-summary {
      padding-left: 20px;
      padding-right: 20px;
      padding-top: 25px;
      width: 100%;
      border-bottom: 2px solid #e0e0e0;
      padding-bottom: 20px;
  
      span {
        font-size: 26px;
        color: #10125A;
        font-weight: bold;
      }
    }

    .list-summary{
      flex-direction: column;
      display: flex;
      padding-left: 20px;
      padding-right: 20px;
      margin-top: 30px;
      padding-bottom: 50px;
  
      .title-list-summary{
        display: flex;
        flex-direction: row;
        .left-title{
          display: flex ;
          flex: 1;
          align-items: flex-start;
          span {
            font-size: 16px;
            color: #000;
            font-weight: 600;
          }
        }
        .right-title{
          display: flex;
          flex: 1;
          justify-content: flex-end;
          span {
            font-size: 14px;
            color: #000;
            font-weight: 700;
          }
        }
      }
    }
  }

}

.select-quantity {
  background-color: #10125A;
  color: #fff;
  border-radius: 6px;
  padding-left: 2px;
  padding-right: 2px;
  font-size: 16px;
  padding-top: 2px;
  padding-bottom: 2px;
  font-weight: bold;
}

.container-discount-points {
  display: flex;
  flex-direction: row;
  border: 1px solid #EC1C24;
  border-radius: 6px;
  width: 100%;
  position: relative;

  .item-discount {
    flex-direction: column;
    flex: 1;
    display: flex;
    align-items: center;
    justify-content: center;
    height: 80px;

    span {
      font-size: 12px;
      color: black;
    }

    .title {
      background-color: #EC1C24;
      border-bottom-left-radius: 4px;
      border-top-left-radius: 4px;
      width: 100%;
      flex: 1;
      display: flex;
      align-items: center;
      justify-content: center;
      
      span {
        font-size: 12px;
        color: #fff;
        font-weight: 500;
      }
    }

    .sub-title {
      display: flex;
      align-items: center;
      justify-content: center;
      flex-direction: column;
      height: 40px;
      span {
        font-weight: 600;
        color: #616161;
      }
    }

  }

  .item-points {
    flex-direction: column;
    flex: 1;
    display: flex;
    align-items: center;
    justify-content: center;
    height: 80px;
    span {
      font-size: 12px;
      color: black;
    }

    .title {
      background-color: #EC1C24;
      border-bottom-right-radius: 4px;
      border-top-right-radius: 4px;
      flex: 1;
      width: 100%;
      display: flex;
      align-items: center;
      justify-content: center;
      
      span {
        font-size: 12px;
        color: #fff;
        font-weight: 500;
      }
    }

    .sub-title {
      display: flex;
      align-items: center;
      justify-content: center;
      height: 40px;
      span {
        font-weight: 600;
        color: #616161;
        font-size: 18px;
      }
    }
  }
}

.text-validate {
  font-size: 12px;
  text-align: center;
  font-weight: 600;

  @media only screen and (max-width: 992px) {
    font-size: 10px;
  }

}

.title-screen {
  font-size: 20px;
  font-weight: bold;
  color: #10125A;
  text-align: center;
  margin-top: 30px;
}