@import url("https://use.typekit.net/psi4xcn.css");

.container-cart {
  display: flex;
  flex-direction: column;
  width: 100%;

  @media only screen and (max-width: 992px) {
    padding-left: 5px;
    padding-right: 5px;
  }

  @media only screen and (max-width: 992px) {
    flex-direction: column;
  }

}

.container-cart-items {
  display: flex;
  flex-direction: column;
  flex: 1;
  border: 2px solid #e0e0e0;
  border-radius: 12px;
  
  @media only screen and (max-width: 992px) {
    border: none;
  }
  
  .container-cart-2 {
    width: 70%;
    flex-direction: row;
    flex: 1;
    @media only screen and (max-width: 992px) {
      width: 100%;
    }
  
  }
  
  .title-cart {
    padding-left: 30px;
    padding-right: 30px;
    padding-top: 20px;
    width: 100%;
    border-bottom: 2px solid #e0e0e0;
    padding-bottom: 20px;

    @media only screen and (max-width: 992px) {
      padding-left: 15px;
      padding-right: 15px;
    }

    span {
      font-size: 30px;
      color: #10125A;
      font-weight: bold;
    }
  }

  .list-products{
    flex-direction: column;
    display: flex;
    padding-left: 30px;
    padding-right: 30px;
    margin-top: 30px;

    @media only screen and (max-width: 992px) {
      padding-left: 15px;
      padding-right: 15px;
      margin-top: 5px;
    }

    .title-list-products{
      display: flex;
      flex-direction: row;
      .left-title{
        display: flex ;
        flex: 1;
        align-items: flex-start;
        span {
          font-size: 14px;
          color: gray;
          font-weight: bold;
          @media only screen and (max-width: 992px) {
            font-size: 14px;
          }
        }
      }
      .right-title{
        display: flex;
        flex: 1;
        justify-content: flex-end;
        span {
          font-size: 1px;
          color: gray;
          font-weight: bold;
          @media only screen and (max-width: 992px) {
            font-size: 14px;
          }
        }
      }
    }
  }
}

.container-summary-items {
  display: flex;
  flex-direction: column;
  padding-left: 20px;
  padding-right: 20px;
  width: 400px;

  @media only screen and (max-width: 992px) {
    width: 100%;
    padding-left: 0px;
    padding-right: 0px;
    position: fixed;
    background: #fff;
    bottom: 0;
    z-index: 999;
    margin-left: -5px;
  }

  .container-avaible-points{
    display: flex;
    flex-direction: column;
    height: 90px;
    border-radius: 12px;

    .title-points {
      flex: 0.5;
      display: flex;
      align-items: center;
      justify-content: center;
      background-color: #EC1C24;
      border-top-left-radius: 12px;
      border-top-right-radius: 12px;

      span {
        font-size: 16px;
        font-weight: bold;
        color: #fff;
      }
    }

    .points-value {
      flex: 1;
      display: flex;
      align-items: center;
      justify-content: center;
      overflow: scroll;
      border: 2px solid #e0e0e0;
      border-bottom-left-radius: 12px;
      border-bottom-right-radius: 12px;
      border-top: none;
      span {
        font-size: 16px;
        font-weight: bold;
      }
    }

  }

  .container-summary {

    border: 2px solid #e0e0e0;
    border-radius: 10px;

    @media only screen and (max-width: 992px) {
      border-radius: 0px;
      border: none;
    }

    .title-summary {
      padding-left: 20px;
      padding-right: 20px;
      padding-top: 22px;
      width: 100%;
      border-bottom: 2px solid #e0e0e0;
      padding-bottom: 20px;
  
      span {
        font-size: 26px;
        color: #10125A;
        font-weight: bold;
      }
    }

    .list-summary{
      flex-direction: column;
      display: flex;
      padding-left: 20px;
      padding-right: 20px;
      margin-top: 30px;
      padding-bottom: 50px;
  
      .title-list-summary{
        display: flex;
        flex-direction: row;
        .left-title{
          display: flex ;
          flex: 1;
          align-items: flex-start;
          span {
            font-size: 14px;
            color: #000;
            font-weight: 600;
          }
        }
        .right-title{
          display: flex;
          flex: 1;
          justify-content: flex-end;
          span {
            font-size: 14px;
            color: #000;
            font-weight: 700;
          }
        }
      }
    }
  }

}

.select-quantity {
  background-color: #10125A;
  color: #fff;
  border-radius: 6px;
  padding-left: 2px;
  padding-right: 2px;
  font-size: 16px;
  padding-top: 2px;
  padding-bottom: 2px;
  font-weight: bold;
}

.title-column {
  display: flex;
  padding: 10px;
  margin-right: 20px;

  .font-20 {
    font-size: 20px;
    font-weight: 600;
  }

  span {
    font-size: 12px;
    color: #616161;
    font-weight: 700;
  }
}

.container-alert {
  display: flex;
  flex-direction: row;
  align-items: center;
  padding-left: 20px;
  padding-right: 20px;
  border: 2px solid #EC1C24;
  border-radius: 10px;
  padding-top: 15px;
  padding-bottom: 15px;
  background-color: rgba(236, 28, 36, 0.1);
  .icon {
    img {
      height: 40px;
      width: 40px;
    }
  }

  .text-alert {
    flex-direction: column;
    display: flex;
    padding-left: 20px;

    .title {
      color: #EC1C24;
      font-size: 18px;
      font-weight: 600;
    }

    .sub-title {
      font-size: 14px;
      font-weight: 600;
      margin-top: 6px;
    }
  }

}

.atributes-product-container {
  display: flex;
  flex-direction: row;

  .item-atribute {
    display: flex;
    flex-direction: column;
    margin-top: 10px;
    margin-left: 15px;

    span {
      font-size: 14px;
      font-weight: 700;
      color: #616161;
    }

    .value {
      margin-top: 6px;
      color: #000;
    }
  }
}

.text-resum {
  font-size: 18px;
  font-weight: bold;
  color: #000;
  padding-bottom: 10px;
}