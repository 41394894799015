@import url("https://use.typekit.net/psi4xcn.css");

.container-industria {
  display: flex;
  flex-direction: row;
  width: 100%;
  
}

.container-usar-pontos {
  display: flex;
  flex-direction: row;
  width: 100%;

  @media only screen and (max-width: 992px) {
    flex-direction: column;
  }


  .products-pontos {
    display: flex;
    flex: 1;

    .container-industria {
      .select-industria {
        border: 1px solid #10125A;
        margin-left: 20px;
        border-radius: 5px;
        min-width: 300px;
        padding-left: 10px;
        padding-right: 10px;
        font-size: 16px;
        padding-top: 2px;
        padding-bottom: 2px;
        @media only screen and (max-width: 992px) {
          width: 100%;
          margin-left: 0px;
          margin-top: 30px;
        }
      }
      @media only screen and (max-width: 992px) {
        width: 100%;
        width: 95%;
      }

    }
    .container-select-industria {
      display: flex;
      flex-direction: row;
      border: 1px solid #10125A;
      border-radius: 5px;
      width: 250px;
      margin-left: 30px;
      .item-select {
        padding: 5px;
        display: flex;
        flex: 1;
        span {
          font-size: 16px;
        }
      }

      .item-arrow {
        border-left: 1px solid #10125A;
        display: flex;
        align-items: center;
        justify-content: center;
        padding-left: 10px;
        padding-right: 10px;
        img {
          width: 15px;
          height: 15px;
        }
      }
    }
  }

  .container-pontos {
    display: flex;
    flex-direction: column;
    @media only screen and (max-width: 992px) {
      width: 100%;
    }

  }

  .container-item-pontos {
    flex-direction: column;
    display: flex;
    border-radius: 8px;
    border: 1px solid #eeeeee;
    margin-top: 20px;
    @media only screen and (max-width: 992px) {
      width: 95%;
      margin-right: 2.5%;
    }
    .title {
      flex-direction: row;
      display: flex;
      justify-content: center;
      align-items: center;
      border-top-left-radius: 8px;
      border-top-right-radius: 8px;
      background-color: #e0e0e0;
      padding-left: 10px;
      padding-right: 10px;
      padding-top: 5px;
      padding-bottom: 5px;

      span {
        font-size: 14px;
        font-weight: 700;
        color: #EC1C24;
        margin-left: 10px;
      }
    }

    .pontos {
      display: flex;
      justify-content: center;
      align-items: center;
      padding: 8px;

      span {
        font-size: 20px;
        font-weight: bold;
        color: #10125A;
      }
    }

  }
}


.container-discount-points {
  display: flex;
  flex-direction: row;
  border: 1px solid #EC1C24;
  border-radius: 6px;
  width: 100%;
  position: relative;

  .item-discount {
    flex-direction: column;
    flex: 1;
    display: flex;
    align-items: center;
    justify-content: center;
    height: 80px;

    span {
      font-size: 12px;
      color: black;
    }

    .title {
      background-color: #EC1C24;
      border-bottom-left-radius: 4px;
      border-top-left-radius: 4px;
      width: 100%;
      flex: 1;
      display: flex;
      align-items: center;
      justify-content: center;
      
      span {
        font-size: 12px;
        color: #fff;
        font-weight: 500;
      }
    }

    .sub-title {
      display: flex;
      align-items: center;
      justify-content: center;
      height: 40px;
      span {
        font-weight: 600;
        color: #616161;
      }
    }

  }

  .item-points {
    flex-direction: column;
    flex: 1;
    display: flex;
    align-items: center;
    justify-content: center;
    height: 80px;
    span {
      font-size: 12px;
      color: black;
    }

    .title {
      background-color: #EC1C24;
      border-bottom-right-radius: 4px;
      border-top-right-radius: 4px;
      flex: 1;
      width: 100%;
      display: flex;
      align-items: center;
      justify-content: center;
      
      span {
        font-size: 12px;
        color: #fff;
        font-weight: 500;
      }
    }

    .sub-title {
      display: flex;
      align-items: center;
      justify-content: center;
      height: 40px;
      span {
        font-weight: 600;
        color: #616161;
      }
    }
  }
}

.container-quant-addcart {
  display: flex;
  flex-direction: row;
  width: 100%;
  .item-quant {
    margin-right: 6px;
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: #10125A;
    border-radius: 8px;
    select {
      outline: none;
    }
    span {
      font-weight: 600;
      color: #616161;
      font-size: 14px;
    }
  }

  .item-cart {
    background-color: #10125A;
    flex: 1;
    display: flex;
    justify-content: center;
    align-items: center;
    height: 35px;
    border-radius: 8px;
    border: none;
    span {
      font-weight: 600;
      color: #fff;
      font-size: 12px;

      @media only screen and (max-width: 992px) {
        font-size: 10px;
      }

    }
  }
  
}

.no-points {
  width: 400px;
  height: 300px;
  @media only screen and (max-width: 992px) {
    width: 300px;
    height: 220px;
    margin-top: 30px;
  }
}